import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "10px",
          fontSize: 12,
          textAlign: "left",
          //   borderBottom: "1px solid rgba(224, 224, 224, 1)",
        },
      },
    },
  },
});
