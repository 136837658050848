import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/firestore"; // Ensure Firestore is imported
import { db } from "../firebase";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import BasicModal from "../components/BasicModal";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ProductInfoById from "./ProductInfoById";
import { getDocs, collection, Timestamp } from "firebase/firestore";
import { getCategoryAndSubcategory, fetchUserInfo } from "../utils/functions";
import "../styles/styles.css";
import { useProductStatus } from "../ProductStatusContext";
import ProductTable from "../components/ProductTable";

function ProductsScreen() {
  const [products, setProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProductUserId, setSelectedProductUserId] = useState(null);
  const [userInfoMap, setUserInfoMap] = useState({});
  const [statusProd, setStatusProd] = useState(null);
  const { setNumStatusCero } = useProductStatus();

  // States for date selection modal and filtered products
  const [openDateModal, setOpenDateModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateError, setDateError] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [openFilteredModal, setOpenFilteredModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (selectedProductUserId) {
      const fetchAndSetUserInfo = async () => {
        const user = await fetchUserInfo(selectedProductUserId);
        setUserInfoMap((prevMap) => ({
          ...prevMap,
          [selectedProductUserId]: user,
        }));
      };
      fetchAndSetUserInfo();
    }
  }, [selectedProductUserId]);

  const fetchProducts = async () => {
    try {
      console.log("Consultando productos en Firestore..."); // Log de consulta
      const productsCollection = collection(db, "productos");
      const productsSnapshot = await getDocs(productsCollection);
      const productsData = await Promise.all(
        productsSnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const createAt = data.createAt
            ? data.createAt.toDate().toLocaleDateString()
            : "No Date";
          const { categoria, subcategoria, userId } = data;

          let categoriaNombre = "";
          let subcategoriaNombre = "";

          if (categoria && subcategoria) {
            try {
              const names = await getCategoryAndSubcategory(
                categoria,
                subcategoria
              );
              categoriaNombre = names.categoriaNombre;
              subcategoriaNombre = names.subcategoriaNombre;
            } catch (error) {
              console.error(
                "Error fetching category and subcategory names:",
                error
              );
            }
          }

          return {
            id: doc.id,
            ...data,
            createAt,
            categoriaNombre,
            subcategoriaNombre,
            userId,
          };
        })
      );

      console.log(`Se encontraron ${productsData.length} productos.`); // Log de cantidad de productos
      productsData.sort((a, b) =>
        a.status === 0 && b.status !== 0
          ? -1
          : a.status !== 0 && b.status === 0
          ? 1
          : 0
      );
      setProducts(productsData);

      const countStatusCero = productsData.filter((p) => p.status === 0).length;
      setNumStatusCero(countStatusCero);

      const userIds = [
        ...new Set(productsData.map((product) => product.userId)),
      ];
      const userInfos = await Promise.all(userIds.map(fetchUserInfo));
      const userInfoMap = userInfos.reduce((map, userInfo) => {
        map[userInfo.uid] = userInfo;
        return map;
      }, {});

      setUserInfoMap(userInfoMap);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleViewClick = (productId, userId, status) => {
    setSelectedProductId(productId);
    setSelectedProductUserId(userId);
    setStatusProd(status);
    setOpenModal(true);
  };

  const handleCloseModal = async () => {
    setOpenModal(false);
    setSelectedProductId(null);
    setUserInfoMap((prevMap) => ({
      ...prevMap,
      [selectedProductUserId]: null,
    }));
    await fetchProducts();
  };

  const handleOpenDateModal = () => setOpenDateModal(true);
  const handleCloseDateModal = () => setOpenDateModal(false);

  const handleDateChange = (field, value) => {
    if (field === "start") {
      setStartDate(value);
      if (endDate && value > endDate) {
        setDateError(
          "La fecha de inicio no puede ser mayor que la fecha de fin."
        );
      } else {
        setDateError("");
      }
    } else {
      setEndDate(value);
      if (startDate && value < startDate) {
        setDateError(
          "La fecha de fin no puede ser menor que la fecha de inicio."
        );
      } else {
        setDateError("");
      }
    }
  };

  const handleApplyDateFilter = async () => {
    // valida fecha de inputs
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (isNaN(startDateObj.getTime()) || isNaN(endDateObj.getTime())) {
      console.error("Error: Invalid date format");
      return;
    }

    // Fetch products desde Firestore
    try {
      const productsCollection = collection(db, "productos");
      const productsSnapshot = await getDocs(productsCollection);

      const filteredProductsArray = productsSnapshot.docs
        .map((doc) => {
          const data = doc.data();
          const createAt =
            data.createAt instanceof Timestamp
              ? new Date(data.createAt.seconds * 1000)
              : new Date(data.createAt);

          return createAt >= startDateObj && createAt <= endDateObj
            ? {
                id: doc.id,
                nombre: data.nombre,
                userId: data.userId,
                createAt: createAt.toISOString().split("T")[0],
              }
            : null;
        })
        .filter(Boolean);

      filteredProductsArray.sort((a, b) =>
        a.userId < b.userId ? -1 : a.userId > b.userId ? 1 : 0
      );

      setFilteredProducts(filteredProductsArray);
      setOpenFilteredModal(true);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleCloseFilteredModal = () => {
    setOpenFilteredModal(false);
    setFilteredProducts([]);
  };

  return (
    <div style={{ padding: 20 }}>
      <h2>Productos</h2>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDateModal}
        style={{ marginTop: "20px" }}
      >
        Seleccionar Fechas
      </Button>
      <ProductTable
        products={products}
        handleViewClick={handleViewClick}
        userInfoMap={userInfoMap}
      />

      <BasicModal
        open={openModal}
        onClose={handleCloseModal}
        component={ProductInfoById}
        componentProps={{
          productId: selectedProductId,
          userInfo: userInfoMap[selectedProductUserId],
          statusProd,
          setStatusProd,
        }}
        width="75%"
        style={{
          minWidth: "200px",
          maxWidth: "600px",
          width: "75%",
          margin: "0 auto",
        }}
      />

      {/* Modal de selección de fechas */}
      <Modal
        open={openDateModal}
        onClose={handleCloseDateModal}
        aria-labelledby="modal-fechas"
        aria-describedby="modal-seleccionar-fechas"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <h3>Seleccionar Fechas</h3>
          <TextField
            label="Fecha de Inicio"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={startDate}
            onChange={(e) => handleDateChange("start", e.target.value)}
            error={Boolean(dateError)}
            helperText={dateError}
            margin="normal"
          />
          <TextField
            label="Fecha de Fin"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={endDate}
            onChange={(e) => handleDateChange("end", e.target.value)}
            error={Boolean(dateError)}
            helperText={dateError}
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyDateFilter}
            style={{ marginTop: "20px" }}
          >
            Aplicar Filtro
          </Button>
        </div>
      </Modal>

      {/* Modal de productos filtrados */}
      <Modal
        open={openFilteredModal}
        onClose={handleCloseFilteredModal}
        aria-labelledby="modal-productos-filtrados"
        aria-describedby="modal-lista-productos-filtrados"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            overflowY: "auto",
            maxHeight: "80%",
          }}
        >
          <h3>Productos Filtrados</h3>
          <br></br>
          {filteredProducts.length === 0 ? (
            <Typography>
              No se encontraron productos en este rango de fechas.
            </Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="tableHeaderCell">Usuario</TableCell>
                    <TableCell className="tableHeaderCell">Nombre</TableCell>
                    <TableCell className="tableHeaderCell">
                      Fecha de Creación
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts.map((product) => (
                    <TableRow key={product.id}>
                      {" "}
                      <TableCell>
                        {userInfoMap[product.userId]?.nombre || "Cargando..."}
                      </TableCell>
                      <TableCell>{product.nombre}</TableCell>
                      <TableCell>{product.createAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default ProductsScreen;
