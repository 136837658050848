import React, { useState, useEffect } from "react";
import {
  Modal,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Stack,
} from "@mui/material";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import Skeleton from "@mui/material/Skeleton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import SourceIcon from "@mui/icons-material/Source";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "../styles/styles.css";
import { fetchUserInfoByIdWithDates } from "../utils/functions";

export const EditUserModal = ({ userId, close }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [formState, setFormState] = useState({
    nombre: "",
    email: "",
    telefono: "",
    cedula: "",
  });

  useEffect(() => {
    if (userId) {
      const fetchUserData = async () => {
        try {
          const user = await fetchUserInfoByIdWithDates(userId);
          console.log("Fetched user:", user);
          setUserInfo(user);

          setFormState({
            nombre: user?.nombre || "",
            email: user?.email || "",
            telefono: user?.telefono || "",
            cedula: user?.cedula || "",
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
      fetchUserData();
    }
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (!userId) {
        console.error("No userId provided");
        return;
      }

      const userRef = doc(db, "users", userId); // Asegúrate de que "users" sea el nombre correcto de tu colección
      await updateDoc(userRef, {
        nombre: formState.nombre,
        email: formState.email,
        telefono: formState.telefono,
        cedula: formState.cedula,
      });

      console.log("User updated successfully");

      //   if (onSave) {
      //     onSave(formState); // Llama a onSave si se pasa como prop
      //   }

      close(); // Cierra el modal
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <div
      className="modal-container"
      style={{ display: "flex", gap: 5, flexDirection: "column" }}
    >
      <h4>Editar Usuario</h4>
      <div className="user-container" style={{ flexDirection: "column" }}>
        {userInfo ? (
          <>
            <TableContainer component={Paper} className="user-info-table">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <AccountCircleIcon className="info-icon" />
                    </TableCell>
                    <TableCell>
                      <b>Nombre:</b>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="nombre"
                        value={formState.nombre}
                        onChange={handleInputChange}
                        fullWidth
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <EmailIcon className="info-icon" />
                    </TableCell>
                    <TableCell>
                      <b>Email:</b>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="email"
                        value={formState.email}
                        onChange={handleInputChange}
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <PhoneIcon className="info-icon" />
                    </TableCell>
                    <TableCell>
                      <b>Teléfono:</b>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="telefono"
                        value={formState.telefono}
                        onChange={handleInputChange}
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <SourceIcon className="info-icon" />
                    </TableCell>
                    <TableCell>
                      <b>Cédula:</b>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="cedula"
                        value={formState.cedula}
                        onChange={handleInputChange}
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <CalendarTodayIcon className="info-icon" />
                    </TableCell>
                    <TableCell>
                      <b>Fecha de creación:</b>
                    </TableCell>
                    <TableCell>{userInfo.createdAt}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div
              className="modal-actions"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                size="small"
              >
                Guardar
              </Button> */}
              <Button
                variant="outlined"
                color="secondary"
                onClick={close}
                size="small"
              >
                Cancelar
              </Button>
            </div>
          </>
        ) : (
          <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="rectangular" width={210} height={100} />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
        )}
      </div>
    </div>
  );
};
