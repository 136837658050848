import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDOkl0LJJX78UeYi1zgUS7WS3gtKQerpCY",

  authDomain: "el-punto-62786.firebaseapp.com",
  projectId: "el-punto-62786",
  storageBucket: "el-punto-62786.appspot.com",
  messagingSenderId: "471809721576",
  appId: "1:471809721576:web:2f990e07f796295796355d",
  measurementId: "G-RMTRM1DCKG",
};

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);

const logout = () => {
  localStorage.removeItem("isLoggedIn");
};

export { firebaseApp, db, logout };
