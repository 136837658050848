import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchUserInfoByIdWithDates } from "../utils/functions";
import "../styles/styles.css";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SourceIcon from "@mui/icons-material/Source";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import InventoryIcon from "@mui/icons-material/Inventory";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";

export const User = ({ userId }) => {
  // const { userId } = useParams();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const getUserInfo = async () => {
      const user = await fetchUserInfoByIdWithDates(userId);
      setUserInfo(user);
    };
    getUserInfo();
  }, [userId]);

  return (
    <>
      <h4>Datos del usuario</h4>
      <div className="user-container">
        {userInfo ? (
          <>
            <div className="user-details">
              <TableContainer component={Paper} className="user-info-table">
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className="tableHeaderCell">
                        <Grid3x3Icon className="info-icon" />
                      </TableCell>
                      <TableCell>
                        <b>User ID:</b>
                      </TableCell>
                      <TableCell>{userId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="tableHeaderCell">
                        <AccountCircleIcon className="info-icon" />
                      </TableCell>
                      <TableCell>
                        <b>Nombre:</b>
                      </TableCell>
                      <TableCell>{userInfo.nombre}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="tableHeaderCell">
                        <EmailIcon className="info-icon" />
                      </TableCell>
                      <TableCell>
                        <b>Email:</b>
                      </TableCell>
                      <TableCell>{userInfo.email}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="tableHeaderCell">
                        <PhoneIcon className="info-icon" />
                      </TableCell>
                      <TableCell>
                        <b>Teléfono:</b>
                      </TableCell>
                      <TableCell>{userInfo.telefono}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="tableHeaderCell">
                        <SourceIcon className="info-icon" />
                      </TableCell>
                      <TableCell>
                        <b>Cédula:</b>
                      </TableCell>
                      <TableCell>{userInfo.cedula}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="tableHeaderCell">
                        <CalendarTodayIcon className="info-icon" />
                      </TableCell>
                      <TableCell>
                        <b>Fecha de creación:</b>
                      </TableCell>
                      <TableCell>{userInfo.createdAt}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="history-container">
              <div style={{ display: "flex", gap: 20 }}>
                <div className="avatar-container">
                  {userInfo.photoURL ? (
                    <img
                      src={userInfo.photoURL}
                      alt="User Avatar"
                      className="avatar-image"
                    />
                  ) : (
                    <div className="default-avatar">
                      <AccountBoxIcon className="default-avatar-icon" />
                    </div>
                  )}
                  {userInfo.photoURL ? null : (
                    <p className="no-avatar-text">
                      No hay foto de perfil disponible
                    </p>
                  )}
                </div>
              </div>
              {/* <h3 className="history-title">Historial en ElPunto</h3> */}
              <div className="history-cards">
                <div className="history-card products-card">
                  <ShoppingCartIcon className="card-icon" />
                  <h4>Productos</h4>
                  <p className="card-value">{userInfo.productosCount}</p>
                </div>
                <div className="history-card purchases-card">
                  <LocalMallIcon className="card-icon" />
                  <h4>Compras</h4>
                  <p className="card-value">{userInfo.comprasCount}</p>
                </div>
                <div className="history-card sales-card">
                  <InventoryIcon className="card-icon" />
                  <h4>Ventas</h4>
                  <p className="card-value">{userInfo.ventasCount}</p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Stack spacing={1}>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton variant="circular" width={100} height={100} />
            <Skeleton variant="rectangular" width={210} height={60} />
            <Skeleton variant="rounded" width={210} height={60} />
          </Stack>
        )}
      </div>
    </>
  );
};
