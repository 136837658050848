import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { getCategoryName, getSubcategoryName } from "../utils/functions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
};

const ImageGallery = ({ images, onImageClick }) => {
  return (
    <div style={{ display: "flex", gap: 10 }}>
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`product-${index}`}
          style={{
            width: 80,
            height: 80,
            objectFit: "cover",
            cursor: "pointer",
            borderRadius: 10,
          }}
          onClick={() => onImageClick(image)}
        />
      ))}
    </div>
  );
};

const ProductInfoById = ({
  productId,
  userInfo,
  statusProd,
  setStatusProd,
}) => {
  const [productInfo, setProductInfo] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [comment, setComment] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subcategoryName, setSubcategoryName] = useState("");

  useEffect(() => {
    const fetchProductInfo = async () => {
      try {
        const productsCollectionRef = collection(db, "productos");
        const q = query(productsCollectionRef, where("id", "==", productId));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (doc) => {
          const data = doc.data();
          const createdAt = data.createAt.toDate().toLocaleDateString();

          // Fetch category and subcategory names
          const fetchedCategoryName = await getCategoryName(data.categoria);
          const fetchedSubcategoryName = await getSubcategoryName(
            data.categoria,
            data.subcategoria
          );

          setProductInfo({ ...data, createdAt });
          setCategoryName(fetchedCategoryName);
          setSubcategoryName(fetchedSubcategoryName);
        });
      } catch (error) {
        console.error("Error fetching product info:", error);
      }
    };

    fetchProductInfo();
  }, [productId]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handleApprove = async () => {
    if (productInfo) {
      try {
        const productDocRef = doc(db, "productos", productInfo.id);
        await updateDoc(productDocRef, { status: 1 });
        setProductInfo((prevInfo) => ({ ...prevInfo, status: 1 }));
        setStatusProd(1); // Actualiza el estado del producto en ProductsScreen
        console.log("Product approved:", productInfo.id); // Verifica
      } catch (error) {
        console.error("Error updating product status:", error);
      }
    }
  };

  const handleNoApprove = () => {
    setOpenCommentModal(true);
  };

  const handleSaveComment = async () => {
    if (productInfo && comment.trim()) {
      try {
        const productDocRef = doc(db, "productos", productInfo.id);
        await updateDoc(productDocRef, {
          status: 3,
          comentarios: comment,
        });
        setProductInfo((prevInfo) => ({
          ...prevInfo,
          status: 3,
          comentarios: comment,
        }));
        setOpenCommentModal(false);
        setComment("");
        setStatusProd(3); // Actualiza el estado del producto en ProductsScreen
        console.log("Product not approved with comment:", productInfo.id); // Verifica
      } catch (error) {
        console.error("Error updating product status with comment:", error);
      }
    }
  };

  const handleCloseCommentModal = () => {
    setOpenCommentModal(false);
    setComment("");
  };

  return (
    <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
      {productInfo ? (
        <>
          <div style={{ flex: 1 }}>
            <div style={{ flexDirection: "row" }}>
              <h2 style={{ margin: 0, marginBottom: "10px" }}>Producto</h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>
                  <b>Status: </b>
                  <span
                    style={{
                      color:
                        productInfo.status === 0
                          ? "orange" // Color para "Por aprobar"
                          : productInfo.status === 1
                          ? "green" // Color para "Aprobado"
                          : productInfo.status === 2
                          ? "blue" // Color para "Aprobado | Inactivo"
                          : "red", // Color para "No aprobado"
                    }}
                  >
                    {productInfo.status === 0
                      ? "Por aprobar"
                      : productInfo.status === 1
                      ? "Aprobado"
                      : productInfo.status === 2
                      ? "Aprobado | Inactivo"
                      : "No aprobado"}
                  </span>
                </p>

                {productInfo.status === 0 ? (
                  <Stack direction="row" spacing={1}>
                    <Button
                      size="small"
                      startIcon={<CheckCircleIcon />}
                      variant="contained"
                      color="success"
                      style={{ fontSize: "11px", textTransform: "capitalize" }}
                      onClick={handleApprove}
                    >
                      Aprobar
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<CancelIcon />}
                      color="error"
                      style={{ fontSize: "11px", textTransform: "capitalize" }}
                      onClick={handleNoApprove}
                    >
                      No aprobar
                    </Button>
                  </Stack>
                ) : productInfo.status === 1 ? (
                  <Stack direction="row" spacing={1}>
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={<CancelIcon />}
                      color="error"
                      style={{ fontSize: "11px", textTransform: "capitalize" }}
                      onClick={handleNoApprove}
                    >
                      No aprobar
                    </Button>
                  </Stack>
                ) : productInfo.status === 3 ? (
                  <Stack direction="row" spacing={1}>
                    <Button
                      size="small"
                      startIcon={<CheckCircleIcon />}
                      variant="contained"
                      color="success"
                      style={{ fontSize: "11px", textTransform: "capitalize" }}
                      onClick={handleApprove}
                    >
                      Aprobar
                    </Button>
                  </Stack>
                ) : null}
              </div>
            </div>
            <hr />

            <p style={{ fontSize: "12px" }}>
              <b>Product ID:</b> {productId}
              <br />
              <b>Nombre:</b> {productInfo.nombre}
              <br />
              <b>Descripción:</b> {productInfo.descripcion}
              <br />
              <b>Precio:</b> {productInfo.precio}
              <br />
              <b>Categoria:</b> {categoryName || "Cargando..."}
              <br />
              <b>Subcategoria:</b> {subcategoryName || "Cargando..."}
              <br />
              <b>Fecha de creación:</b> {productInfo.createdAt}
            </p>
            <hr />
            <h4 style={{ margin: 0, marginBottom: "15px" }}>
              Imágenes del producto
            </h4>
            <div
              style={{
                border: "1px solid #999",
                padding: 10,
                borderRadius: 10,
                overflowX: "auto",
                maxWidth: "100%",
              }}
            >
              <ImageGallery
                images={productInfo.images}
                onImageClick={handleImageClick}
              />
            </div>
          </div>
        </>
      ) : (
        <p>Cargando información del producto...</p>
      )}
      <Modal open={!!selectedImage} onClose={handleClose}>
        <Box sx={style}>
          <img
            src={selectedImage}
            alt="selected-product"
            style={{ width: "100%" }}
          />
        </Box>
      </Modal>
      <Modal open={openCommentModal} onClose={handleCloseCommentModal}>
        <Box sx={style}>
          <h3>Razón de la no aprobación</h3>
          <TextField
            label="Comentarios"
            multiline
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveComment}
            style={{ marginTop: "10px" }}
          >
            Guardar comentario
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ProductInfoById;
