import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";

import { getStatusText } from "../utils/functions";

function ProductTable({ products, handleViewClick, userInfoMap }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [paginatedProducts, setPaginatedProducts] = useState([]);

  useEffect(() => {
    // Filtrar productos basado en el query de búsqueda
    const filtered = products.filter((product) =>
      product.nombre.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchQuery, products]);

  useEffect(() => {
    // Paginación de los productos filtrados
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    setPaginatedProducts(filteredProducts.slice(startIndex, endIndex));
  }, [filteredProducts, page, rowsPerPage]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Resetear a la primera página al buscar
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Resetear a la primera página al cambiar filas por página
  };

  return (
    <div>
      <TextField
        label="Buscar Producto"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchQuery}
        onChange={handleSearch}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableHeaderCell">Producto</TableCell>
              <TableCell className="tableHeaderCell">
                Categoría / Subcategoría
              </TableCell>
              <TableCell className="tableHeaderCell">Cantidad</TableCell>
              <TableCell className="tableHeaderCell">Precio</TableCell>
              <TableCell className="tableHeaderCell">Estado</TableCell>
              <TableCell className="tableHeaderCell">Creación</TableCell>
              <TableCell className="tableHeaderCell">Usuario</TableCell>
              <TableCell className="tableHeaderCell"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedProducts.map((product) => (
              <TableRow key={product.id}>
                <TableCell component="th" scope="row">
                  {product.nombre}
                </TableCell>
                <TableCell>
                  {product.categoriaNombre} / {product.subcategoriaNombre}
                </TableCell>
                <TableCell>{product.cantidad}</TableCell>
                <TableCell>{product.precio}</TableCell>
                <TableCell>{getStatusText(product.status)}</TableCell>
                <TableCell>{product.createAt}</TableCell>
                <TableCell>
                  <Link
                    to={`/User/${product.userId}`}
                    style={{ textDecoration: "none", color: "blue" }}
                  >
                    {userInfoMap[product.userId]?.nombre || "Cargando..."}
                  </Link>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() =>
                      handleViewClick(
                        product.id,
                        product.userId,
                        product.status
                      )
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredProducts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default ProductTable;
